var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }
  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }
    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }
    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }
    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};
var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
    f,
    y,
    t,
    g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;
  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }
  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");
    while (g && (g = 0, op[0] && (_ = 0)), _) try {
      if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
      if (y = 0, t) op = [op[0] & 2, t.value];
      switch (op[0]) {
        case 0:
        case 1:
          t = op;
          break;
        case 4:
          _.label++;
          return {
            value: op[1],
            done: false
          };
        case 5:
          _.label++;
          y = op[1];
          op = [0];
          continue;
        case 7:
          op = _.ops.pop();
          _.trys.pop();
          continue;
        default:
          if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
            _ = 0;
            continue;
          }
          if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
            _.label = op[1];
            break;
          }
          if (op[0] === 6 && _.label < t[1]) {
            _.label = t[1];
            t = op;
            break;
          }
          if (t && _.label < t[2]) {
            _.label = t[2];
            _.ops.push(op);
            break;
          }
          if (t[2]) _.ops.pop();
          _.trys.pop();
          continue;
      }
      op = body.call(thisArg, _);
    } catch (e) {
      op = [6, e];
      y = 0;
    } finally {
      f = t = 0;
    }
    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};
var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { LocalizedText, useLocalization } from '@shared-ui/localization-context';
import { UitkBanner } from 'uitk-react-banner';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkEmailInput, UitkPasswordInput } from 'uitk-react-input';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkLoader } from 'uitk-react-loader';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
import { LayoutLite } from 'shared/components/layout/layout-lite';
import { useOktaAuth } from 'shared/hooks/use-okta-auth';
import { validation } from 'shared/utils/validation';
import { useLogin } from './login.mutations';
export default function LoginForm() {
  var auth = useOktaAuth();
  var l10n = useLocalization();
  var navigate = useNavigate();
  var login = useLogin();
  var _a = __read(useState(false), 2),
    loginInProgress = _a[0],
    setLoginInProgress = _a[1];
  var _b = __read(useState(null), 2),
    loginError = _b[0],
    setLoginError = _b[1];
  var _c = useForm({
      mode: 'onBlur',
      defaultValues: {
        email: '',
        password: ''
      }
    }),
    control = _c.control,
    errors = _c.formState.errors,
    handleSubmit = _c.handleSubmit;
  useEffect(function () {
    auth.isAuthenticated({
      onExpiredToken: 'remove'
    }).then(function (authenticated) {
      if (authenticated) {
        navigate('/app/dash');
      }
    });
  }, [auth]);
  function doLogin(userCredentials) {
    return __awaiter(this, void 0, void 0, function () {
      var _this = this;
      return __generator(this, function (_a) {
        setLoginError(null);
        setLoginInProgress(true);
        login.mutate(userCredentials, {
          onError: function (_a) {
            var status = _a.status;
            // not exhaustive because this is temporary
            // more statuses here: https://github.com/okta/okta-auth-js/blob/master/docs/authn.md#transactionstatus
            if (status === 'LOCKED_OUT') {
              setLoginError('Your account has been locked. Please try again later.');
            } else if (status === 'PASSWORD_EXPIRED') {
              setLoginError('Your password has expired. Please reset your password.');
            } else {
              setLoginError('Invalid email or password. Please try again.');
            }
            setLoginInProgress(false);
          },
          onSuccess: function (_a) {
            return __awaiter(_this, [_a], void 0, function (_b) {
              var sessionToken = _b.sessionToken;
              return __generator(this, function (_c) {
                switch (_c.label) {
                  case 0:
                    return [4 /*yield*/, auth.signInWithRedirect({
                      sessionToken: sessionToken
                    })];
                  case 1:
                    _c.sent();
                    return [2 /*return*/];
                }
              });
            });
          }
        });
        return [2 /*return*/];
      });
    });
  }
  return React.createElement(LayoutLite, {
    centered: true
  }, React.createElement(UitkLayoutFlex, {
    direction: 'column',
    space: "three"
  }, loginError && React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'six'
    }
  }, React.createElement("div", null, React.createElement(UitkBanner, {
    theme: "error"
  }, React.createElement(UitkText, null, loginError))))), React.createElement(UitkLayoutFlexItem, null, React.createElement("form", {
    name: 'signInForm',
    onSubmit: handleSubmit(doLogin),
    method: "POST"
  }, React.createElement(UitkLayoutFlex, {
    direction: 'column',
    space: "three"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkSpacing, {
    margin: {
      blockend: 'two'
    }
  }, React.createElement("div", null, React.createElement(LocalizedText, {
    message: "signin.form.title.enter_your_afhub_details"
  }), React.createElement("br", null), React.createElement(LocalizedText, {
    message: "signin.form.title.dont_use_pz_details"
  })))), React.createElement(UitkLayoutFlexItem, null, React.createElement(Controller, {
    control: control,
    name: "email",
    defaultValue: "",
    rules: {
      required: l10n.formatText('signin.form.message.email.enter_your_email_address'),
      pattern: {
        value: validation.EMAIL_REGEX,
        message: l10n.formatText('signin.form.message.email.enter_a_valid_email_address')
      }
    },
    render: function (_a) {
      var _b;
      var _c = _a.field,
        onChange = _c.onChange,
        onBlur = _c.onBlur,
        value = _c.value,
        name = _c.name;
      return React.createElement(UitkEmailInput, {
        id: "signInFormEmailInput",
        name: name,
        label: l10n.formatText('signin.form.label.email'),
        value: value,
        onChange: function (_a) {
          var newValue = _a.target.value;
          onChange(newValue);
        },
        onBlur: onBlur,
        invalid: (_b = errors.email) === null || _b === void 0 ? void 0 : _b.message,
        "data-cy": "sign-in-email-field"
      });
    }
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement(Controller, {
    control: control,
    name: "password",
    defaultValue: "",
    rules: {
      required: l10n.formatText('signin.form.message.password.enter_your_password')
    },
    render: function (_a) {
      var _b;
      var _c = _a.field,
        onChange = _c.onChange,
        onBlur = _c.onBlur,
        value = _c.value,
        name = _c.name;
      return React.createElement(UitkPasswordInput, {
        id: "loginFormPasswordInput",
        label: l10n.formatText('signin.form.label.password'),
        name: name,
        value: value,
        onChange: function (_a) {
          var newValue = _a.target.value;
          onChange(newValue);
        },
        onBlur: onBlur,
        invalid: (_b = errors.password) === null || _b === void 0 ? void 0 : _b.message,
        "data-cy": "sign-in-password-field"
      });
    }
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkPrimaryButton, {
    onClickCapture: function () {
      // trackEvent('affhub_signin.submitted');
    },
    id: "signInFormSubmitButton",
    type: "submit",
    disabled: login.isPending || loginInProgress,
    className: 'spacing-top-bottom-24',
    size: UitkButtonSize.LARGE,
    "data-cy": "sign-in-submit"
  }, login.isPending || loginInProgress ? React.createElement(UitkLoader, {
    isSmall: true,
    isDarkBackground: true
  }) : l10n.formatText('signin.form.label.submit'))), React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkLayoutFlex, {
    direction: 'row',
    justifyContent: 'space-between'
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(UitkText, null, React.createElement(LocalizedText, {
    message: "signin.form.label.need_an_account"
  }), ' ', React.createElement(UitkLink, {
    inline: true,
    size: "medium"
  }, React.createElement(Link, {
    to: "/signup"
  }, React.createElement(LocalizedText, {
    message: "signin.form.label.signup"
  }))))), React.createElement(UitkLayoutFlexItem, {
    alignSelf: "center"
  }, React.createElement("div", null, React.createElement(UitkLink, {
    size: "medium",
    "data-cy": "sign-in-forgot-password-link"
  }, React.createElement("a", {
    href: "/eg-affiliates/forgot-password"
  }, React.createElement(LocalizedText, {
    message: "signin.form.label.forgot_password"
  }))))))))))));
}